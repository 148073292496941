/* HamburgerMenu.css */

.hamburger-menu {
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: -20px;
}

.hamburger-menu span {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s;
}

.hamburger-menu span:nth-child(1) {
  top: 0;
}

.hamburger-menu span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.hamburger-menu span:nth-child(3) {
  bottom: 0;
}

.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.menu-container a {
  display: block;
  padding: 0.5rem 0;
  color: #333;
  text-decoration: none;
}

.menu-container a:hover {
  color: #000;
  text-decoration: none;
}

/* Responsive styles */
@media (min-width: 768px) {
  .hamburger-menu {
    display: none;
  }

  .header-links {
    display: flex;
    align-items: center;
  }
}
