.card-container {
  margin-bottom: 1rem;
  min-height: 19rem;
  /* width: 19rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  /* background-color: #fff; */
  padding: 1rem;
  /* margin-right: 10px; */
}



.card-points {
    font-size: 0.8rem;
    color: #555;
    text-decoration: none;
}

