.image {

    height: 30rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

/* .image1 {
    width: 5rem;
    background-size: cover;
    background-position: center;
} */

.bg {
    background-color: white;
    display: inline-block;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    padding: 20px; /* Add padding to the container */
   
  }

  .download-btn {
    display: inline-block;
    background-color: #007aff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .download-btn:hover {
    background-color: #0062cc;
  }
  
  .download-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }

  .image-container video.image {
    object-fit: cover; /* or object-fit: contain; depending on your preference */
  }
  
  
  .bg:first-child {
    position: absolute;
    left: 120%;
    top: 40%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  

  @media only screen and (max-width: 767px) {
  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    height: 25rem;
  }
  
  .bg:first-child {
    display: none;
  }

  
}

@media only screen and (min-width: 768px) {
  .image-container {
    margin-left: -5rem;
  }
}

@media (max-width: 768px) {
  .scribble-dots {
    display: none;
  }
}

@media (max-width: 767px) {
  .custom-margin {
    margin-top: 1rem !important;
  }
}


.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 2rem;
  height: 2rem;
}

.circle-back {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: rgb(74, 73, 156);
  z-index: 1;
  margin-right: 1rem;
}

.circle-front {
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #090909;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.circle-front i {
  color: #fff;
  font-size: 10px;
}
