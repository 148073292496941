/* Header.css */

.logo-container {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
  
  .project {
    margin-right: 5px;
  }
  
  .tagline {
    font-size: 14px;
    font-weight: 300;
    margin-top: -5px;
  }
  
  /* Responsive styles */
  @media (max-width: 767px) {
    .header {
      display: flex;
      justify-content: center;
    }
  
    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (min-width: 768px) {
    .header-links {
      display: flex;
      align-items: center;
    }
  }

  a {
    text-decoration: none;
  }
  