.logo {
  max-height: 40px;
}

* {
  font-family: 'Courier Prime', monospace;
  /* overflow-x: hidden ; */
}

.zen {
  font-family: 'Zen Dots', cursive;
}

.courier {
  font-family: 'Courier Prime', monospace;
}

a {
  color: #333;
}

a:hover {
  color: #000;
  text-decoration: none;
}

.App {
  background-color: rgb(251, 248, 241);
  min-height: 100vh; /* Change height to min-height to adapt to content height */
}
